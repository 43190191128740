<template>
  <div>
    <div class="companies">
      <ul class="logo-group">
<!--        <li class="logo-item">-->
<!--          <img class="STARS1" src="../../assets/pic/star.svg" alt="">-->
<!--          <img class="logo" src="../../assets/company_logo/1_gold.svg" alt="">-->
<!--          <a href="">-->
<!--            <img src="../../assets/company.png" alt="">-->
<!--            <span class="span">ЭЙ БИ ӨҮ ИНТЕРНЭШНЛ ХХК</span>-->
<!--          </a>-->
<!--        </li>-->

        <li class="logo-item" :key="index" v-for="(bai, index) in ds_baiguullaga">
          <div v-if="bai.torolgishuun">
            <img class="STARS1" src="../../assets/pic/star.svg" alt="">
          </div>
          <div v-else>
            <img class="STARS1" src="../../assets/pic/star.svg" alt="" v-if="bai.gishuunbus_torol === 'Алтан багц'">
          </div>
          <div v-if="bai.torolgishuun">
            <img class="logo" src="../../assets/company_logo/1_gold.svg" alt="" v-if="bai.torolgishuun === 'Алтан багц'">
            <img class="logo" src="../../assets/company_logo/1_silver.svg" alt="" v-if="bai.torolgishuun === 'Мөнгөн багц'">
            <img class="logo" src="../../assets/company_logo/1_bronze.svg" alt="" v-if="bai.torolgishuun === 'Хүрэл багц'">
          </div>
          <div v-else>
            <img class="logo" src="../../assets/company_logo/2_gold.svg" alt="" v-if="bai.gishuunbus_torol === 'Алтан багц'">
            <img class="logo" src="../../assets/company_logo/2_silver.svg" alt="" v-if="bai.gishuunbus_torol === 'Мөнгөн багц'">
            <img class="logo" src="../../assets/company_logo/2_bronze.svg" alt="" v-if="bai.gishuunbus_torol === 'Хүрэл багц'">
          </div>
          <router-link :to="`/baiguullaga_tanilts/${bai.id}`">
            <img :src="IMAGE_URL + bai.logo" alt="" v-if="bai.logo != ''">
            <img src="../../assets/no-image.png" alt="" v-else>
            <span class="span" v-if="language.short == 'mn'">{{bai.ner_mn}}</span>
            <span class="span" v-else>{{bai.ner_en}}</span>
          </router-link>
          <baiguullaga_follow_btn :company_id="bai.id" type="baiguullaga"></baiguullaga_follow_btn>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  GET_BAIGUULLAGA_LIMIT_FEFTIIN,
  IMAGE_URL,
} from "../../graphql/queries";
import {mapGetters} from "vuex";
const baiguullaga_follow_btn = () => import(/* webpackChunkName: "baiguullaga_follow_btn" */ './baiguullaga_follow_btn')
export default {
  name: "baiguullaga",
  components: {
    "baiguullaga_follow_btn": baiguullaga_follow_btn
  },
  data () {
    return {
      loading: true,
      IMAGE_URL: IMAGE_URL,
      ds_baiguullaga: []
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    this.getGet_baiguullaga();
  },
  methods: {
    getGet_baiguullaga() {
      this.$apollo.query({query: GET_BAIGUULLAGA_LIMIT_FEFTIIN}).then(({data}) => {
        this.ds_baiguullaga = data.ds_baiguullaga;
        //console.log(this.ds_baiguullaga);
      })
    },
  },

}
</script>